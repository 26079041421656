import React from 'react';
import logo from '../images/corrupt0-logo.png'
import logo2x from '../images/corrupt0-logo.png'
import logo3x from '../images/corrupt0-logo.png'

const Logo = ({classes}) => {
  const className = 'mx-auto object-center ' + `${classes || 'mt-32'}`

  return (
    <img className={` ${className}`} alt="Corrupt0" src={logo} srcSet={`${logo} 1x, ${logo2x} 2x, ${logo3x} 3x`} width="280px" height="70px"/>
  )
}

export default Logo;