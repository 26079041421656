import React from "react";

const Footer = () => (
  <footer className="" style={{backgroundColor: '#2A2A2A'}}>
    <nav className="flex justify-between max-w-6xl p-4 mx-auto text-sm md:p-8">
      <p>
        <span className="text-xl font-avenir text-white tracking-widest">Corrupt0</span>
      </p>

        <p className="text-right">
            <a href={"https://actai.co/TermCondition"}>
                <span className="font-avenir text-white tracking-widest text-right">
                        Terms & Conditions
                </span>
            </a>
            <a href={"https://actai.co/Policy"} className="ml-5">
                <span className="font-avenir text-white tracking-widest text-right">
                        Privacy Policy
                </span>
            </a>
        </p>

    </nav>
</footer>
)

export default Footer;